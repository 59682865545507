<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Users Management</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                @click="openFormUser = true"
                dense>
                <v-list-item-icon>
                  <v-icon>mdi-account-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create User</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                to="departments"
                dense>
                <v-list-item-icon>
                  <v-icon>mdi-folder-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>User Department</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <user-list :userInfo="usersList.data" />
    <v-dialog
      v-model="openFormUser"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Create User</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model.trim="user.fullName"
              label="Fullname"
              outlined
              dense
              required />

            <v-text-field
              v-model.trim="user.username"
              label="Username"
              :rules="[rules.min]"
              outlined
              dense
              required />

            <v-text-field
              v-model.trim="user.password"
              type="password"
              label="Password"
              :rules="[rules.required, rules.password]"
              outlined
              dense
              required />

            <v-text-field
              v-model.trim="passwordConfirm"
              type="password"
              label="Confirm Password"
              :rules="[rules.required, rules.passwordConfirm, rules.password]"
              dense
              outlined />

            <v-select
              v-model="user.department"
              :items="departments.departments"
              item-text="name"
              item-value="_id"
              label="User Department"
              outlined
              dense />

            <v-select
              v-model="user.roles"
              :items="roles.roles"
              item-text="name"
              item-value="_id"
              label="User Role"
              outlined
              dense />

            <v-checkbox
              v-model="user.activated"
              class="checkboxMarginSet"
              dense
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="createUser">
            Create
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUser = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Users',
  components: {
    UserList: () => import('@/components/userManagement/userList.vue')
  },
  data() {
    return {
      submenu: [
        { text: 'Create User', icon: 'mdi-account-plus-outline', name: '' },
        { text: 'User Department', icon: 'mdi-folder-account-outline', name: 'departments' },
      ],
      openFormUser: false,
      user: {
        username: '',
        password: '',
        fullName: '',
        department: '',
        // departmentName: '',
        roles: '',
        // rolesDescription: [],
        // scopes: [],
        activated: true,
      },
      role: {},
      department: {},
      passwordConfirm: '',
      rules: {
        required: (value) => !!value || 'Required, Please enter information.',
        passwordConfirm: () => (this.user.password === this.passwordConfirm) || 'Password must match',
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            'Password minimum 8 characters with at least one capital letter, a number and a special character.'
          );
        },
        min: (v) => v.length >= 6 || 'Username minimum 6 characters.',
        // passwordRule: (v) => v === /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$.!%*#?&])[A-Za-z\d@$.!%*#?&]{8,}$/ || 
        // 'Password should not be empty, at least one letter, one number and one special character',
      }
    };
  },

  async created() {
    await this.$store.dispatch('user/getAllUser');
    await this.$store.dispatch('department/getAllDepartments');
    await this.$store.dispatch('userRole/getAllRoles');
  },

  computed: {
    ...mapGetters({
      usersList: 'user/GET_USERS',
      departments: 'department/GET_DEPARTMENTS',
      roles: 'userRole/GET_ROLES'
    })
  },

  methods: {
    async createUser() {
      await this.$store.dispatch('user/createUser', this.user);

      this.user.username = '';
      this.user.password = '';
      this.passwordConfirm = '';
      this.user.fullName = '';
      this.user.department = '';
      this.user.roles = '';
      this.user.activated = true;

      this.openFormUser = false;
    }
  }
};
</script>

<style>
</style>
